import { useNavigate } from 'react-router-dom'
import React from 'react'
import { DateTime } from 'luxon'
import * as PropTypes from 'prop-types'

export function ChargerPeriodSelect({ siteId, charger, period }) {
  const navigate = useNavigate()

  function viewPeriod(e) {
    e.preventDefault()
    const period = e.target.value
    if (period === 'none') {
      return
    }
    navigate(`/site/${siteId}/charger/${charger.id}/${period}`)
  }

  return <select onChange={viewPeriod} value={period || 'none'}>
    {getAvailablePeriods().map(period => {
      return <option key={period.value} value={period.value}>{period.label}</option>
    })}
  </select>
}

ChargerPeriodSelect.propTypes = {
  siteId: PropTypes.any.isRequired,
  charger: PropTypes.object.isRequired,
}

function getAvailablePeriods() {
  const start = DateTime.fromObject({ year: 2021, month: 1 })
  const end = DateTime.now()

  const result = []
  for (let ts = start; ts < end; ts = ts.plus({ months: 1 })) {
    const period = ts.toFormat('yyyy-MM')
    result.unshift({ label: period, value: period })
  }
  result.unshift({
    label: 'välj en period',
    value: 'none',
  })
  return result
}
