import { DateTime } from 'luxon'

const months = {
  1: 'Januari',
  2: 'Februari',
  3: 'Mars',
  4: 'April',
  5: 'Maj',
  6: 'Juni',
  7: 'Juli',
  8: 'Augusti',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'December',
}

export default function parsePeriod(period, now) {
  now = now || DateTime.now().setZone('Europe/Stockholm')

  const from = DateTime.fromFormat(period, 'yyyy-MM', { zone: 'Europe/Stockholm' })
  const periodEnd = from.plus({ months: 1 })
  const startOfThisHour = now.startOf('hour')
  const to = DateTime.min(startOfThisHour, periodEnd)

  return {
    short: period,
    from,
    to,
    humanReadable: `${months[from.month]} ${from.year}`,
    complete: +to === +periodEnd,
    isValid: from.isValid,
  }
}
