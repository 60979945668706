// NOTE: Don't change, will be extracted to its own package
import config from './config'

const defaultConfig = {
  energyTax: NaN,
  taxReduction: NaN,
  vatRate: NaN,
}

export default function taxCosts(ts, configs) {
  const conf = config.select(ts, configs) || defaultConfig
  return {
    energyTax: conf.energyTax,
    taxReduction: conf.taxReduction,
    vatRate: conf.vatRate,
  }
}
