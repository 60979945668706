// This file wraps all the files in src/energy as the plan is for that code to be
// extracted to a separate package at some point.
import spotPrices from 'energy/spotprices'
import { mergeEnergyUsage } from 'energy/energy'
import { DateTime } from 'luxon'

// The configuration used for cost calculations. New entries should be added
// when our energy costs change such as when Ellevio increasing price or when
// the energy tax is changed, which historically has been done once per year.
//
// Instead of changing the existing config, new entries should be added with
// a validFrom date that is either when the price change occurred or when the
// price change was first registered in the system. We don't want completed
// months to change, only current or future months.
//
// The entries should be sorted with new entries with higher validFrom first.
const config = {
  energyTrade: [{
    validFrom: '2021-01-01',
    area: 'SE3',
    energyTariff: 'hourly',
    energyCert: 0.018,
    surcharge: 0.025,
  },], energyNetwork: [{
    validFrom: '2021-01-01',
    networkBenefitHighLoad: 0.028,
    networkBenefitLowLoad: 0.00216,
    networkFeeHighLoad: 0.56,
    networkFeeLowLoad: 0.096,
    highLoadTimeStart: 6,
    highLoadTimeStop: 22,
    highLoadTimeMonths: [11, 12, 1, 2, 3],
  }], taxes: [{
    validFrom: '2023-01-01',
    energyTax: 0.392,
    taxReduction: 0.60,
    vatRate: 0.25,
  }, {
    validFrom: '2022-01-01',
    energyTax: 0.36,
    taxReduction: 0.60,
    vatRate: 0.25,
  }, {
    validFrom: '2021-01-01',
    energyTax: 0.356,
    taxReduction: 0.60,
    vatRate: 0.25,
  }],
}

export function getSpotPrices(from, to) {
  return spotPrices.get(config.energyTrade[0].area, from, to)
}

export function toBought(lifeTimeEnergyHourly) {
  return lifeTimeEnergyHourly.map(entry => {
    return {
      ts: DateTime.fromISO(entry.date, { zone: 'utc' }),
      kwh: entry.consumption,
    }
  })
}

export function addEnergyCosts(bought, spotPrices) {
  return mergeEnergyUsage({
    bought, spotPrices, energyTrade: config.energyTrade, energyNetwork: config.energyNetwork, taxes: config.taxes,
  }).map(entry => {
    // Remove useless data like sold energy that will be NaN anyway
    const {
      ts, boughtKwh, spotPrice, surcharge, energyCert, energyTax, vatRate, networkFee, boughtTrade, boughtEnergyTax, boughtNetworkFee, boughtExVat, boughtVat, boughtTotal
    } = entry

    const stockholmTs = ts.setZone('Europe/Stockholm')
    return {
      // Time related fields
      timestamp: stockholmTs.toISO(),
      year: stockholmTs.year,
      month: stockholmTs.month,
      day: stockholmTs.day,
      hour: stockholmTs.hour,

      // Energy charged
      chargedKwh: boughtKwh,

      // These are the charging costs for this hour, we should name these better
      boughtTrade,
      boughtNetworkFee,
      boughtEnergyTax,
      boughtExVat,
      boughtVat,
      boughtTotal,

      // These are the rates per kWh for this hour
      spotPrice,
      surcharge,
      energyCert,
      networkFee,
      energyTax,
      vatRate, // Maybe overkill but who knows, maybe the VAT for energy will change soon?
    }
  })
}
