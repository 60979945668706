// chargerName, chargerId, period, boughtExVat	boughtVat	boughtTotal
export default function invoice(data, period, accountingNumber) {
  // calculate period string
  const periodFirst = new Date(`${period}-01`);
  const periodLast = new Date(periodFirst.getFullYear(), periodFirst.getMonth() + 1, 0);
  const periodStr = `${period}-01 - ${period}-${periodLast.getDate()}`
  return Object.values(data.reduce((prev, curr) => {
    prev[curr.chargerId] = prev[curr.chargerId] || {
      chargerName: curr.chargerName,
      chargerId: curr.chargerId,
      chargedKwh: 0,
      boughtExVat: 0,
      boughtVat: 0,
      boughtTotal: 0,
    }
    prev[curr.chargerId].boughtExVat += curr.boughtExVat
    prev[curr.chargerId].boughtVat += curr.boughtVat
    prev[curr.chargerId].boughtTotal += curr.boughtTotal
    prev[curr.chargerId].chargedKwh += curr.chargedKwh
    return prev
  }, {}))
	.map(entry => {
    const roundKr = (value) => {
      return Math.round(value * 100) / 100.0;
    }
    const averageCostPerKwh = roundKr(entry.boughtTotal / entry.chargedKwh)
    const boughtKwh = roundKr(entry.chargedKwh);
    return {
      "Beteckning": entry.chargerName,
      "Konto": accountingNumber,
      "Fritext": `${periodStr}: ${boughtKwh} kWh, ${averageCostPerKwh} kr/kWh`,
      "Belopp, exkl moms": roundKr(entry.boughtExVat),
    }
  })
}
