import React from 'react'
import * as PropTypes from 'prop-types'

export default function ChargerName({ charger }) {
  return <span title={`id ${charger.id}`}>{charger.name || 'Okänd'}</span>
}

ChargerName.propTypes = {
  charger: PropTypes.object.isRequired,
}
