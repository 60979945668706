import { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { Bar, CartesianGrid, ComposedChart, Line, ReferenceDot, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { addEnergyCosts, getSpotPrices } from 'lib/chargingCosts'

export default function EnergyPrice() {
  const [costs, setCosts] = useState()

  useEffect(() => {
    if (costs) {
      return
    }

    async function calculateEnergyCosts() {
      const from = DateTime.now().startOf('day').toUTC()
      const to = from.plus({ days: 2 })

      const bought = Array(48).fill(null).map((_, i) => {
        return {
          ts: from.plus({ hours: i }),
          kwh: 1.0,
        }
      })

      const spotPrices = await getSpotPrices(from, to)

      const energyCosts = addEnergyCosts(bought, spotPrices)
        .filter(cost => !isNaN(cost.spotPrice))
        .map(transformCost)

      setCosts(energyCosts)
    }

    calculateEnergyCosts()
  })

  return <>
    {!costs && "Laddar…"}
    {costs && <EnergyGraph costs={costs} />}
  </>
}

function transformCost(cost) {
  const { timestamp, boughtTrade, boughtNetworkFee, boughtEnergyTax, boughtVat, boughtTotal } = cost
  return {
    timestamp,
    boughtTotal: round(boughtTotal),
    boughtTrade: round(boughtTrade),
    boughtNetworkFee: round(boughtNetworkFee),
    boughtEnergyTax: round(boughtEnergyTax),
    boughtVat: round(boughtVat),
  }
}

function round(boughtTotal) {
  return Math.round(boughtTotal * 1000) / 1000
}

function EnergyGraph({ costs }) {
  costs = limitTo24Hours(costs)
  const now = DateTime.now()
  const currentTimestamp = now.startOf('hour').toISO()
  const currentCost = costs.find(cost => cost.timestamp === currentTimestamp)

  return <>
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart data={costs} barCategoryGap={0}>
        <CartesianGrid strokeOpacity={0.3} />
        <Tooltip separator=": " content={CustomTooltip} />
        <Bar unit=" kr" type="step" name="elskatt" dataKey="boughtEnergyTax" strokeWidth={0} fill="rgb(200,65,49)" stackId="1" isAnimationActive={false} />
        <Bar unit=" kr" type="step" name="elnät" dataKey="boughtNetworkFee" strokeWidth={0} fill="rgb(47,139,193)" stackId="1" isAnimationActive={false} />
        <Bar unit=" kr" type="step" name="elhandel" dataKey="boughtTrade" strokeWidth={0} fill="rgb(44,183,108)" stackId="1" isAnimationActive={false} />
        <Bar unit=" kr" type="step" name="moms" dataKey="boughtVat" strokeWidth={0} fill="rgb(243,203,13)" stackId="1" isAnimationActive={false} shape={<BarWithTopBorder strokeWidthOverride={2} stroke="#6A79FD" />} />
        <Line unit=" kr" type="step" name="totalt" dataKey="boughtTotal" isAnimationActive={false} dot={false} strokeWidth={2} stroke="#6A79FD" />
        {currentCost && <ReferenceDot x={currentTimestamp} y={currentCost.boughtTotal} r={10} fill="green" opacity={0.7} stroke="none" />}
        <XAxis type="category" dataKey="timestamp" tickFormatter={tickFormatter} />
        <YAxis unit=" kr" amplitude={0.5} />
      </ComposedChart>
    </ResponsiveContainer>
    <pre style={{ display: 'none' }}>{JSON.stringify(costs, null, '  ')}</pre>
  </>
}

EnergyGraph.propTypes = { costs: PropTypes.array.isRequired }

const dayNames = [
  'Måndag',
  'Tisdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lördag',
  'Söndag',
]

function CustomTooltip({ active, payload, label, separator }) {
  if (!active || !payload || !payload.length) {
    return null
  }

  const ts = DateTime.fromISO(label)
  const nextHour = ts.plus({ hours: 1 })
  return <div className="energy-price-tooltip">
    <p>{`${dayNames[ts.weekday - 1]} ${ts.toFormat('HH:mm')}-${nextHour.toFormat('HH:mm')}`}</p>
    <p><strong>Pris: {payload.at(-1).value} kr</strong></p>
    <ul>
      {payload.slice().reverse().slice(1).map(item => {
        return <li key={item.dataKey}><span style={{ color: item.color }}>{item.name}{separator}{item.value}{item.unit}</span></li>
      })}
    </ul>
  </div>
}

function tickFormatter(timestamp) {
  return DateTime.fromISO(timestamp).hour
}

// Cannot use strokeWidth property name as recharts will overwrite that prop
// with the prop set on the <Bar />
function BarWithTopBorder({ stroke, strokeWidthOverride, fill, x, y, width, height }) {
  return <g>
    <rect x={x} y={y} width={width} height={height} stroke="none" fill={fill} />
    <line x1={x} x2={x + width} y1={y} y2={y} strokeWidth={strokeWidthOverride} stroke={stroke} />
  </g>
}

// This function assumes that we either get 24 or 48 hours (which I think we will)
// but will not return 24 hours if we have >24 hours but <48.
function limitTo24Hours(costs) {
  if (costs.length <= 24) {
    return costs
  }

  const now = DateTime.now()
  const start = Math.max(now.hour - 1, 0)
  return costs.slice(start, start + 24)
}
