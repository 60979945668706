import * as PropTypes from "prop-types"

export default function ErrorMessage({err}) {
  if (err.message) {
    return <p className="error">{err.message}</p>
  }

  return <>
    <p className="error">Ett oväntat fel inträffade:</p>
    <pre className="error">{JSON.stringify(err, null, 2)}</pre>
  </>
}

ErrorMessage.propTypes = {err: PropTypes.any.isRequired}
