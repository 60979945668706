// The purpose of the ComponentLibrary is to simplify development of
// visual components that does not fetch their own data.
import React from 'react'
import ChargerSelect from 'components/ChargerSelect'
import Totals from 'components/Totals'
import Specification from 'components/Specification'
import EnergyPrice from 'components/EnergyPrice'
import { Link } from 'react-router-dom'
import parsePeriod from 'lib/parsePeriod'

const products = [
  {
    "id": 35296,
    "siteKey": "P4SM-A222",
    "name": "BRF Grafikern",
    "levelOfAccess": 3,
    "address": {
      "street": "Mobilgatan 5",
      "buildingNumber": null,
      "zip": "126 26",
      "area": "Hägersten",
      "country": {
        "id": "SE",
        "name": "Sweden",
        "phonePrefix": 0
      },
      "latitude": null,
      "longitude": null,
      "altitude": null
    },
    "siteType": 100,
    "ratedCurrent": 63,
    "partnerId": 20,
    "circuits": [
      {
        "id": 34172,
        "siteId": 35296,
        "circuitPanelId": 2,
        "panelName": "2",
        "ratedCurrent": 50,
        "chargers": [
          {
            "id": "EC075564",
            "name": "Plats 31",
            "levelOfAccess": 3,
            "userRole": 1,
            "productCode": 100,
            "backPlate": {
              "id": "8162F2DA303F04",
              "masterBackPlateId": "8162F2DA303F04",
              "name": null
            },
            "isTemporary": false,
            "color": 1,
            "createdOn": "2020-07-28T06:37:09.753833",
            "updatedOn": "2020-10-12T14:26:57.664024"
          }
        ],
        "masterBackplate": null,
        "useDynamicMaster": false,
        "parentCircuitId": null
      }
    ],
    "equalizers": [],
    "userRole": 1,
    "allowedSiteActions": [
      "AllowToConfigureLevelOfAccess"
    ]
  }
]

const specification = [
  {
    "timestamp": "2022-09-09T16:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 9,
    "hour": 16,
    "chargedKwh": 0,
    "boughtTrade": 0,
    "boughtNetworkFee": 0,
    "boughtEnergyTax": 0,
    "boughtExVat": 0,
    "boughtVat": 0,
    "boughtTotal": 0,
    "spotPrice": 2.81241,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-09T17:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 9,
    "hour": 17,
    "chargedKwh": 4.628232777777612,
    "boughtTrade": 15.824946078432765,
    "boughtNetworkFee": 0.44431034666665076,
    "boughtEnergyTax": 1.6661637999999401,
    "boughtExVat": 17.935420225099357,
    "boughtVat": 4.483855056274839,
    "boughtTotal": 22.419275281374198,
    "spotPrice": 3.37622,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-09T18:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 9,
    "hour": 18,
    "chargedKwh": 8.064354722222106,
    "boughtTrade": 36.59055796823281,
    "boughtNetworkFee": 0.7741780533333222,
    "boughtEnergyTax": 2.903167699999958,
    "boughtExVat": 40.267903721566086,
    "boughtVat": 10.066975930391521,
    "boughtTotal": 50.33487965195761,
    "spotPrice": 4.49432,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-09T19:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 9,
    "hour": 19,
    "chargedKwh": 8.046871944443865,
    "boughtTrade": 39.09572734208052,
    "boughtNetworkFee": 0.772499706666611,
    "boughtEnergyTax": 2.8968738999997914,
    "boughtExVat": 42.76510094874692,
    "boughtVat": 10.69127523718673,
    "boughtTotal": 53.45637618593365,
    "spotPrice": 4.8155,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-09T20:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 9,
    "hour": 20,
    "chargedKwh": 8.063705555555316,
    "boughtTrade": 33.91312326972122,
    "boughtNetworkFee": 0.7741157333333104,
    "boughtEnergyTax": 2.9029339999999135,
    "boughtExVat": 37.590173003054446,
    "boughtVat": 9.397543250763611,
    "boughtTotal": 46.98771625381806,
    "spotPrice": 4.16265,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-09T21:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 9,
    "hour": 21,
    "chargedKwh": 8.143432500000472,
    "boughtTrade": 26.497670708776532,
    "boughtNetworkFee": 0.7817695200000453,
    "boughtEnergyTax": 2.93163570000017,
    "boughtExVat": 30.211075928776744,
    "boughtVat": 7.552768982194186,
    "boughtTotal": 37.76384491097093,
    "spotPrice": 3.21087,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-09T22:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 9,
    "hour": 22,
    "chargedKwh": 7.590820833333055,
    "boughtTrade": 13.427327063874506,
    "boughtNetworkFee": 0.7287187999999732,
    "boughtEnergyTax": 2.7326954999999,
    "boughtExVat": 16.888741363874377,
    "boughtVat": 4.222185340968594,
    "boughtTotal": 21.11092670484297,
    "spotPrice": 1.72589,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-09T23:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 9,
    "hour": 23,
    "chargedKwh": 0.7879013888891677,
    "boughtTrade": 0.7261062829585903,
    "boughtNetworkFee": 0.0756385333333601,
    "boughtEnergyTax": 0.2836445000001004,
    "boughtExVat": 1.0853893162920507,
    "boughtVat": 0.2713473290730127,
    "boughtTotal": 1.3567366453650633,
    "spotPrice": 0.87857,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-10T00:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 10,
    "hour": 0,
    "chargedKwh": 0,
    "boughtTrade": 0,
    "boughtNetworkFee": 0,
    "boughtEnergyTax": 0,
    "boughtExVat": 0,
    "boughtVat": 0,
    "boughtTotal": 0,
    "spotPrice": 0.97092,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-17T22:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 17,
    "hour": 22,
    "chargedKwh": 0,
    "boughtTrade": 0,
    "boughtNetworkFee": 0,
    "boughtEnergyTax": 0,
    "boughtExVat": 0,
    "boughtVat": 0,
    "boughtTotal": 0,
    "spotPrice": 0.73885,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-17T23:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 17,
    "hour": 23,
    "chargedKwh": 6.085351944444483,
    "boughtTrade": 3.0883769653250197,
    "boughtNetworkFee": 0.5841937866666703,
    "boughtEnergyTax": 2.1907267000000137,
    "boughtExVat": 5.863297451991704,
    "boughtVat": 1.465824362997926,
    "boughtTotal": 7.32912181498963,
    "spotPrice": 0.46451,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-18T00:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 18,
    "hour": 0,
    "chargedKwh": 8.098219999999401,
    "boughtTrade": 2.7436769359997975,
    "boughtNetworkFee": 0.7774291199999425,
    "boughtEnergyTax": 2.9153591999997843,
    "boughtExVat": 6.436465255999524,
    "boughtVat": 1.609116313999881,
    "boughtTotal": 8.045581569999404,
    "spotPrice": 0.2958,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-18T01:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 18,
    "hour": 1,
    "chargedKwh": 8.07078027777834,
    "boughtTrade": 2.698788217086299,
    "boughtNetworkFee": 0.7747949066667207,
    "boughtEnergyTax": 2.905480900000202,
    "boughtExVat": 6.379064023753222,
    "boughtVat": 1.5947660059383055,
    "boughtTotal": 7.973830029691528,
    "spotPrice": 0.29139,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-18T02:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 18,
    "hour": 2,
    "chargedKwh": 8.062710000000152,
    "boughtTrade": 2.2732810845000433,
    "boughtNetworkFee": 0.7740201600000146,
    "boughtEnergyTax": 2.9025756000000547,
    "boughtExVat": 5.949876844500112,
    "boughtVat": 1.487469211125028,
    "boughtTotal": 7.43734605562514,
    "spotPrice": 0.23895,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-18T03:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 18,
    "hour": 3,
    "chargedKwh": 1.7359641666666903,
    "boughtTrade": 0.5825374954083413,
    "boughtNetworkFee": 0.16665256000000228,
    "boughtEnergyTax": 0.6249471000000085,
    "boughtExVat": 1.3741371554083521,
    "boughtVat": 0.34353428885208803,
    "boughtTotal": 1.7176714442604402,
    "spotPrice": 0.29257,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-18T04:00:00.000+02:00",
    "year": 2022,
    "month": 9,
    "day": 18,
    "hour": 4,
    "chargedKwh": 0,
    "boughtTrade": 0,
    "boughtNetworkFee": 0,
    "boughtEnergyTax": 0,
    "boughtExVat": 0,
    "boughtVat": 0,
    "boughtTotal": 0,
    "spotPrice": 0.36633,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-19T18:00:00.000+01:00",
    "year": 2022,
    "month": 9,
    "day": 19,
    "hour": 18,
    "chargedKwh": 0,
    "boughtTrade": 0,
    "boughtNetworkFee": 0,
    "boughtEnergyTax": 0,
    "boughtExVat": 0,
    "boughtVat": 0,
    "boughtTotal": 0,
    "spotPrice": 0.64861,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-19T19:00:00.000+01:00",
    "year": 2022,
    "month": 9,
    "day": 19,
    "hour": 19,
    "chargedKwh": 0.0049272222222498385,
    "boughtTrade": 0.0005332239888918776,
    "boughtNetworkFee": 0.0004730133333359845,
    "boughtEnergyTax": 0.0017738000000099418,
    "boughtExVat": 0.002780037322237804,
    "boughtVat": 0.000695009330559451,
    "boughtTotal": 0.0034750466527972554,
    "spotPrice": 0.06522,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
  {
    "timestamp": "2022-09-19T20:00:00.000+01:00",
    "year": 2022,
    "month": 9,
    "day": 19,
    "hour": 20,
    "chargedKwh": 0,
    "boughtTrade": 0,
    "boughtNetworkFee": 0,
    "boughtEnergyTax": 0,
    "boughtExVat": 0,
    "boughtVat": 0,
    "boughtTotal": 0,
    "spotPrice": 0.42562,
    "surcharge": 0.025,
    "energyCert": 0.018,
    "networkFee": 0.096,
    "energyTax": 0.36,
    "vatRate": 0.25
  },
]

export default function ComponentLibrary() {
  return <>
    <h1>Komponenter</h1>
    <Link to="/">Till inloggningen</Link>

    <h2>Elpris</h2>
    <EnergyPrice />
    <p>&nbsp;</p>

    <h2>ChargerSelect</h2>
    <ChargerSelect site={products[0]} />
    <p>&nbsp;</p>

    <h2>Totals</h2>
    <Totals specification={specification} period={parsePeriod('2022-09')} />
    <p>&nbsp;</p>

    <h2>Specification</h2>
    <Specification specification={specification} period={specification[0].timestamp.substring(0, 7)} />
  </>
}

