// NOTE: Don't change, will be extracted to its own package
import config from './config'

const defaultConfig = {
  networkBenefitHighLoad: NaN,
  networkBenefitLowLoad: NaN,
  networkFeeHighLoad: NaN,
  networkFeeLowLoad: NaN,
  highLoadTimeStart: NaN,
  highLoadTimeStop: NaN,
  highLoadTimeMonths: [],
}

export default function networkCosts(ts, configs) {
  const conf = config.select(ts, configs) || defaultConfig
  return {
    networkFee: networkFee(ts, conf),
    networkBenefit: networkBenefit(ts, conf),
  }
}

function networkFee(ts, config) {
  if (isHighLoadTime(ts, config)) {
    return config.networkFeeHighLoad
  }
  return config.networkFeeLowLoad
}

function networkBenefit(ts, config) {
  if (isHighLoadTime(ts, config)) {
    return config.networkBenefitHighLoad
  }
  return config.networkBenefitLowLoad
}

function isHighLoadTime(ts, config) {
  if (ts.month > 3 && ts.month < 11) {
    return false
  }
  if (isWeekend(ts)) {
    return false
  }
  if (isHoliday(ts)) {
    return false
  }
  return ts.hour >= config.highLoadTimeStart && ts.hour < config.highLoadTimeStop;
}

function isWeekend(ts) {
  return [6, 7].includes(ts.weekday)
}

// New Year's Day, Epiphany, Maundy Thursday, Good Friday, Easter Monday, Christmas Eve, Christmas Day, Boxing Day, New Year's Eve
// Nyårsdagen, trettondag jul, skärtorsdagen, långfredagen, annandag påsk, julafton, juldagen, annandag jul och nyårsafton
function isHoliday(ts) {
  // New Year's Day, Epiphany
  // Nyårsdagen, trettondag jul
  if (ts.month === 1 && [1, 6].includes(ts.day)) {
    return true
  }
  // Christmas Eve, Christmas Day, Boxing Day, New Year's Eve
  // Julafton, juldagen, annandag jul och nyårsafton
  if (ts.month === 12 && [24, 25, 26, 31].includes(ts.day)) {
    return true
  }
  return isEasterHoliday(ts)
}

const easter = {
  2021: {
    4: {
      1: true,
      2: true,
      5: true,
    }
  },
  2022: {
    4: {
      14: true,
      15: true,
      18: true,
    }
  },
  2023: {
    4: {
      6: true,
      7: true,
      10: true,
    }
  },
}

// Maundy Thursday, Good Friday, Easter Monday
// Skärtorsdagen, långfredagen, annandag påsk
function isEasterHoliday(ts) {
  return easter[ts.year]?.[ts.month]?.[ts.day] || false
}
