import React, { useState } from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import Papa from 'papaparse'
import download from 'lib/download'

export default function Specification({ specification, period }) {
  const [sortAsc, setSortAsc] = useState(true)

  function toggleSorting() {
    setSortAsc(!sortAsc)
  }

  function downloadCsv() {
    download(Papa.unparse(specification), 'text/csv', `charging-${period}.csv`)
  }

  return <>
    <h3>Specifikation</h3>
    <p>I tabellen nedan ser du hur bilen laddats per timme uppdelat i laddsessioner. En laddsession är en eller flera timmar då bilen laddats i följd. Du kan se hur många kWh du laddade per timme, det totala elpriset per kWh den timmen, samt den totala summan för just den timmen.</p>
    <p>Det är den här datan som sammanställningen och faktureringen bygger på. Vill du ha ännu mer detaljer kan du ladda ner datan som ett CSV-dokument.</p>
    <p>
      <button onClick={toggleSorting}>Visa {sortAsc ? 'senaste laddsessionen överst' : 'äldsta laddsessionen överst'}</button>
      <button onClick={downloadCsv}>Ladda ner specifikation som CSV</button>
    </p>
    <table className="specification">
      <thead>
        <tr>
          <th>Timme</th>
          <th>kWh</th>
          <th title="Totalt elpris inklusive moms, kr/kWh">Elpris</th>
          <th title="Summa inklusive moms">Summa</th>
        </tr>
      </thead>
      <tbody>
        {chargingSessions(specification, sortAsc).map((session, i) => {
          return <React.Fragment key={`session-${i}`}>
            {session.rows.map(row => {
              return <tr key={row.timestamp}>
                <td>{DateTime.fromISO(row.timestamp).setZone('Europe/Stockholm').toFormat("yyyy-MM-dd 'kl' HH")}</td>
                <td className="numeric">{round(row.chargedKwh)}</td>
                <td className="numeric">{round((row.spotPrice + row.surcharge + row.energyCert + row.networkFee + row.energyTax) * (1 + row.vatRate))}</td>
                <td className="numeric">{round(row.boughtTotal)}</td>
              </tr>
            })}
            <tr className="session-total">
              <th>Totalt</th>
              <th className="numeric">{round(session.chargedKwh)}</th>
              <th className="numeric">{round(session.boughtTotal / session.chargedKwh)}</th>
              <th className="numeric">{round(session.boughtTotal)}</th>
            </tr>
          </React.Fragment>
        })}
      </tbody>
    </table>
  </>
}

function chargingSessions(specification, sortAsc) {
  let currentSessionIndex = undefined

  return specification
    .reduce((result, row) => {
      if (!row.chargedKwh) {
        currentSessionIndex = undefined
        return result
      }
      if (!currentSessionIndex) {
        currentSessionIndex = {
          chargedKwh: 0,
          boughtTotal: 0,
          rows: [],
        }
        result.push(currentSessionIndex)
      }
      currentSessionIndex.rows.push(row)
      currentSessionIndex.chargedKwh += row.chargedKwh
      currentSessionIndex.boughtTotal += row.boughtTotal
      return result
    }, [])
    .filter(chargingSession => round(chargingSession.boughtTotal) !== "0.00")
    .sort(chargingSessionCompare(sortAsc))
}

Specification.propTypes = {
  specification: PropTypes.any,
}

function round(val) {
  if (val === 0) {
    return 0
  }
  return val.toFixed(2)
}

function chargingSessionCompare(sortAsc) {
  // Ascending order is the default, for descending we simply flip the sign of the returned value
  const order = sortAsc ? 1 : -1
  return (a, b) => {
    if (a.rows[0].timestamp > b.rows[0].timestamp) return 1 * order
    if (a.rows[0].timestamp < b.rows[0].timestamp) return -1 * order
    return 0
  }
}
