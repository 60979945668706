import { DateTime } from 'luxon'

function get(area, from, to) {
  return fetch(`https://nordpool.kitbuilder.se/api.php?area=${area}&from=${encodeDate(from)}&to=${encodeDate(to)}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  })
    .then(res => res.json())
    // Convert dates returned from server to same format as used internally
    .then(spotPrices => {
      return Object.fromEntries(Object.entries(spotPrices).map(([k, v]) => {
        const utcDate = DateTime
          .fromISO(k)
          .toUTC()
          .toISO()
        return [utcDate, v]
      }))
    })
    .then(assertCompleteTimeSeries)
}

/**
 * Verifies that there is one entry for each hour without holes. Will not
 * detect if it's the first or last day that is missing as I'm not sure what
 * edge cases exist. Must the start date and end date always be returned? Is
 * the times inclusive or not?
 *
 * @param spotPrices - An object with spot prices
 * @return spotPrices on success or throws if some hour is missing a price
 */
export function assertCompleteTimeSeries(spotPrices) {
  const times = Object.keys(spotPrices)
  times.forEach((t, i) => {
    if (i === times.length - 1) {
      return
    }

    const nextHour = DateTime.fromISO(t).toUTC().plus({hours: 1}).toISO()
    if (nextHour !== times[i + 1]) {
      throw new Error('Spot price missing for ' + nextHour)
    }
  })
  return spotPrices
}

function encodeDate(from) {
  return encodeURIComponent(from.toISO({ suppressMilliseconds: true }))
}

const spotPrices = {
  get,
}

export default spotPrices
