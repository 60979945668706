import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { useEffect } from 'react'
import { DateTime } from 'luxon'

export default function SiteRedirect() {
  const { products } = useOutletContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (products.length !== 1) {
      return
    }

    const site = products[0]
    const chargers = site.circuits?.flatMap(circuit => circuit.chargers)

    // Most users will have only one charger and will most likely want to view the
    // current month's energy costs.
    if (chargers && chargers.length === 1) {
      const charger = chargers[0]
      const period = DateTime.now().toFormat('yyyy-MM')
      return navigate(`/site/${site.id}/charger/${charger.id}/${period}`)
    }

    return navigate(`/site/${site.id}`)
  })

  if (products.length === 0) {
    return <p>Din användare verkar inte vara kopplad till någon anläggning…</p>
  }

  return <>
    <h1>Välj anläggning</h1>
    <ul>
      {products.map(site => {
        return <li key={site.id}><Link to={`/site/${site.id}`}>{site.name}</Link></li>
      })}
    </ul>
  </>
}
