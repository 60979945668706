// NOTE: Don't change, will be extracted to its own package
import { DateTime } from 'luxon'

// Returns the config element that is valid for time ts. The config elements will first be
// sorted by validFrom. An entry where validFrom is not set will be treated as always valid.
// If there is only a single config element it will also be treated as always valid as
// the validFrom field isn't visible for config with only one element.
function selectConfig(ts, configs) {
  if (configs.length === 1) {
    return configs[0]
  }
  return sort(configs).find(e => {
    if (!e.validFrom) {
      return true
    }
    return ts >= DateTime.fromFormat(e.validFrom, 'yyyy-MM-dd', { zone: 'Europe/Stockholm' })
  })
}

// Sort configs by validFrom so that the largest validFrom is first
function sort(configs) {
  return configs.sort((a, b) => {
    if (a.validFrom === b.validFrom) {
      return 0
    }
    return a.validFrom < b.validFrom ? 1 : -1
  })
}

const config = {
  select: selectConfig,
}

export default config
