import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from 'auth'

// Component with the page parts shown at all pages when logged in.

export default function LoggedInLayout() {
  const location = useLocation()
  const { token, profile, logout } = useAuth()

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return <>
    <h1>Inloggad som {profile?.firstName} {profile?.lastName}</h1>
    <p>
      <button onClick={logout}>Logga ut</button>
    </p>
    <Outlet />
  </>
}
