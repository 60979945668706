import { useNavigate, useOutletContext } from 'react-router-dom'
import { useEffect } from 'react'
import { DateTime } from 'luxon'
import ChargerSelect from 'components/ChargerSelect'

export default function SitePage() {
  const { site } = useOutletContext()
  const navigate = useNavigate()

  useEffect(() => {
    const chargers = site.circuits?.flatMap(circuit => circuit.chargers)

    // Most users will have only one charger and will most likely want to view the
    // current month's energy costs.
    if (chargers && chargers.length === 1) {
      const charger = chargers[0]
      const period = DateTime.now().toFormat('yyyy-MM')
      return navigate(`/site/${site.id}/charger/${charger.id}/${period}`)
    }
  })

  return <ChargerSelect site={site} />
}
