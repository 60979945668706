import React from 'react'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { DateTime } from 'luxon'

export default function AdminPage() {
  const { site } = useOutletContext()
  const navigate = useNavigate()

  const selectMonth = (e) => {
    e.preventDefault()
    navigate(e.target.value)
  }

  const months = new Array(12).fill(undefined).map((_, i) => {
    return DateTime.now().startOf("month").minus({ months: i }).toFormat('yyyy-MM')
  })

  return <>
    <h2>Admin</h2>
    <p><Link to={`/site/${site.id}`}>Tillbaka</Link></p>
    <p>
      Ladda ner fakturaunderlag för månad: <select onChange={selectMonth}>
        <option>Välj månad</option>
        {months.map(month => {
          return <option key={month} value={month}>{month}</option>
        })}
      </select>
    </p>
  </>
}

