import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom'
import isAdmin from 'lib/isAdmin'
import React from 'react'

export default function SiteLayout() {
  const { products } = useOutletContext()
  const { siteid } = useParams()

  const site = products.find(site => site.id === parseInt(siteid))

  return <>
    {isAdmin(site) && <p>Du är administratör för {site.name}, <Link to={`/site/${site.id}/admin`}>gå till admin-sidan</Link></p>}
    <Outlet context={{ site }} />
  </>
}
