import React from 'react'
import { Route, Routes } from "react-router-dom";
import 'App.css';
import { AuthProvider } from 'auth'
import LoginPage from 'components/LoginPage'
import ChargingCosts from 'components/ChargingCosts'
import AdminPage from 'components/AdminPage'
import AdminChargingCosts from 'components/AdminChargingCosts'
import ComponentLibrary from 'components/ComponentLibrary'
import LoggedInLayout from 'components/LoggedInLayout'
import SitesLayout from 'components/SitesLayout'
import SiteRedirect from 'components/SiteRedirect'
import SiteLayout from 'components/SiteLayout'
import ChargerLayout from 'components/ChargerLayout'
import EnergyPricePage from 'components/EnergyPricePage'
import EnergyPrice from 'components/EnergyPrice'
import SitePage from 'components/SitePage'

export class App extends React.Component {
  render() {
    return (
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/energyprice" element={<EnergyPricePage />} />
          <Route element={<LoggedInLayout />}>
            <Route path="/" element={<SitesLayout />}>
              <Route path="/" element={<SiteRedirect />} />
              <Route path="/site/:siteid" element={<SiteLayout />}>
                <Route index element={<SitePage />} />
                <Route path="charger/:chargerid/" element={<ChargerLayout />}>
                  <Route index element={<EnergyPrice />} />
                  <Route path=":period" element={<ChargingCosts />} />
                </Route>
                <Route path="admin" element={<AdminPage />} />
                <Route path="admin/:period" element={<AdminChargingCosts />} />
              </Route>
            </Route>
          </Route>
          <Route path="components" element={<ComponentLibrary />} />
        </Routes>
      </AuthProvider>
    )
  }
}

export default App

