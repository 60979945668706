// NOTE: Don't change, will be extracted to its own package
import networkCosts from 'energy/networkCosts'
import taxCosts from 'energy/taxCosts'
import tradeCosts from 'energy/tradeCosts'

// Creates a data structure compatible with recharts
export function mergeEnergyUsage({ bought, sold, produced, spotPrices, energyTrade, energyNetwork, taxes }) {
  const resultByDate = {}

  if (bought) {
    bought.forEach(row => {
      resultByDate[row['ts']] = resultByDate[row['ts']] || { ts: row['ts'] }
      resultByDate[row['ts']]['boughtKwh'] = row['kwh']
    })
  }

  if (sold) {
    sold.forEach(row => {
      resultByDate[row['ts']] = resultByDate[row['ts']] || { ts: row['ts'] }
      resultByDate[row['ts']]['soldKwh'] = row['kwh']
    })
  }

  if (produced) {
    produced.forEach(row => {
      resultByDate[row['ts']] = resultByDate[row['ts']] || { ts: row['ts'] }
      resultByDate[row['ts']]['producedKwh'] = row['kwh']
    })
  }

  // Add additional data to each row
  for (const [utcDate, row] of Object.entries(resultByDate)) {
    // So far ts has been in utc, now we switch to Europe/Stockholm time zone
    // as that is what is used when calculating costs and for displaying
    const ts = row.ts.setZone('Europe/Stockholm')
    Object.assign(row,
      {
        ts,
        date: ts.toISO(),
      },
      spotPricesForTime(utcDate, spotPrices),
      taxCosts(ts, taxes),
      networkCosts(ts, energyNetwork),
      tradeCosts(ts, energyTrade),
    )
  }

  return Object
    .values(resultByDate)
    .map(calculateSelfConsumption)
    .map(calculateCosts)
}

function calculateSelfConsumption(row) {
  const { producedKwh, soldKwh } = row
  const selfConsumedKwh = (producedKwh || 0) - (soldKwh || 0)

  if (selfConsumedKwh < 0) {
    throw new Error(`Sold more (${soldKwh}) than produced (${producedKwh}) at: ${row['ts'].toISO()}, check imported data's time zones`)
  }

  return Object.assign(row, { selfConsumedKwh })
}

function calculateCosts(row) {
  const { boughtKwh, soldKwh, selfConsumedKwh, spotPrice, energyTariff, fixedPrice, surcharge, energyCert, energyTax, vatRate, taxReduction, networkFee, networkBenefit } = row

  const tradePricePerKwh = energyTariff === 'fixed' ? fixedPrice + energyCert : spotPrice + surcharge + energyCert

  const boughtTrade = boughtKwh * tradePricePerKwh
  const boughtEnergyTax = boughtKwh * energyTax
  const boughtNetworkFee = boughtKwh * networkFee

  const boughtExVat = boughtTrade + boughtNetworkFee + boughtEnergyTax
  const boughtVat = boughtExVat * vatRate
  const boughtTotal = boughtExVat + boughtVat

  const soldTrade = soldKwh * (spotPrice + energyCert)
  const soldTaxReduction = soldKwh * taxReduction
  const soldNetworkBenefit = soldKwh * networkBenefit

  const soldTotal = soldTrade + soldNetworkBenefit + soldTaxReduction

  const selfConsumedTrade = selfConsumedKwh * tradePricePerKwh
  const selfConsumedEnergyTax = selfConsumedKwh * energyTax
  const selfConsumedNetworkFee = selfConsumedKwh * networkFee

  const selfConsumedExVat = selfConsumedTrade + selfConsumedNetworkFee + selfConsumedEnergyTax
  const selfConsumedVat = selfConsumedExVat * vatRate
  const selfConsumedTotal = selfConsumedExVat + selfConsumedVat

  return Object.assign(row, {
    tradePricePerKwh,
    boughtTrade,
    boughtEnergyTax,
    boughtNetworkFee,
    boughtExVat,
    boughtVat,
    boughtTotal,
    soldTrade,
    soldTaxReduction,
    soldNetworkBenefit,
    soldTotal,
    selfConsumedTrade,
    selfConsumedEnergyTax,
    selfConsumedNetworkFee,
    selfConsumedExVat,
    selfConsumedVat,
    selfConsumedTotal,
  })
}

function spotPricesForTime(date, spotPrices) {
  if (!spotPrices || spotPrices[date] === undefined) {
    return null
  }
  return {
    spotPrice: spotPrices[date],
  }
}
