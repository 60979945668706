import { useAuth } from 'auth'
import React, { useEffect, useRef, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import easee from 'lib/easee'
import * as PropTypes from 'prop-types'

export default function SitesLayout() {
  const { token } = useAuth()
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState(null)
  const firstLoad = useRef(true)

  useEffect(() => {
    if (!firstLoad.current) return
    firstLoad.current = false

    async function fetchProducts() {
      setProducts(await easee.products(token))
      setLoading(false)
    }

    fetchProducts()
  }, [token])

  return <>
    {loading && 'Laddar…'}
    {products && <>
      <SiteSelector products={products} />
      <Outlet context={{ products }} />
    </>}
  </>
}

function SiteSelector({ products }) {
  if (products.length < 2) {
    return null
  }
  return <ul>
    {products.map(site => {
      return <li key={site.id}><Link to={`site/${site.id}`}>{site.name}</Link></li>
    })}
  </ul>
}

SiteSelector.propTypes = { products: PropTypes.any }

