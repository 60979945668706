import React from 'react'

export default function EnergyPricePage() {
  return <>
    <h1>Elpriset</h1>
    <p>Elpriset består av olika delar:</p>
    <ul>
      <li>elhandel - kostnaden för elproduktionen och vissa avgifter</li>
      <li>elnät - kostnaden för överföringen av el till vår fastighet</li>
      <li>skatter - energiskatt och moms</li>
    </ul>
    <h2>Elhandel</h2>
    <p>Den största kostnaden för elhandel är det rörliga elpriset som sätts på elbörsen <a href="components/EnergyPricePage">Nord Pool</a>.</p>
    <p>En lägre del är det påslag som elhandlaren gör. Påslaget är den avgift elhandelsbolaget tar ut för att täcka kostnader de har för fakturering och administration, avgifter de har till Nord Pool och Svenska Kraftnät m.m. BRF Grafikern har God El som elhandlare.</p>
    <p>Den sista delen av elhandelskostnaden är elcertifikat som är en avgift som tas ut i syfte att stödja övergången till förnybara energikällor.</p>
    <h2>Elnät</h2>
    <p>Avgiften för elnät tas ut av den lokala nätägaren (Ellevio) för att täcka kostnaderna de har för att driva elnätet i områden och överföra elen till vår fastighet. Överföringsavgiften varierar mellan hög- och låglasttid.</p>
    <p>Höglasttid är vardagar kl. 06-22 från och med 1 november till och med 31 mars, med undantag för vissa högtider.</p>
    <h2>Skatter</h2>
    <p>Energiskatten är en fast skatt per kWh. Den har historiskt höjts varje år och är 2022 på 36 öre per kWh exklusive moms. Energiskatten brukar räknas till elnätskostnaderna eftersom den faktureras av nätägaren.</p>
    <p>Ovanpå alla dessa kostnader och skatter läggs 25% moms för att få det slutgiltiga elriset.</p>
  </>
}
