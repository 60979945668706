import Papa from 'papaparse'

export function parseChargerSummaryReport(csv) {
  return Papa.parse(csv.substring(csv.indexOf('Charger name;')), {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => {
      return header.toLowerCase()
    }
  }).data.map(row => {
    return {
      name: row['charger name'],
      id: row['charger serial'],
      kwh: row['energy (kwh)'],
    }
  })
}
