import React from 'react'
import { DateTime } from 'luxon'
import easee from 'lib/easee'

const AuthContext = React.createContext(null)

export function useAuth() {
  return React.useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [token, setToken] = React.useState(localStorage.getItem('accessToken'))
  const [profile, setProfile] = React.useState(JSON.parse(localStorage.getItem('profile')))

  const accessTokenValidUntil = DateTime.fromISO(localStorage.getItem('accessTokenValidUntil'))

  if (!accessTokenValidUntil.isValid || accessTokenValidUntil < DateTime.now()) {
    if (token !== undefined) {
      setToken(undefined)
    }
    if (profile !== undefined) {
      setProfile(undefined)
    }
  }

  const login = async (username, password) => {
    const loginData = await easee.login(username, password)
    const profile = await easee.profile(loginData.accessToken)
    setToken(loginData.accessToken)
    setProfile(profile)
    localStorage.setItem('accessToken', loginData.accessToken)
    localStorage.setItem('accessTokenValidUntil', DateTime.now().plus({ seconds: loginData.expiresIn }).toISO())
    localStorage.setItem('profile', JSON.stringify(profile))
  }

  const logout = () => {
    setToken(null)
    setProfile(null)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('accessTokenValidUntil')
    localStorage.removeItem('profile')
  }

  const value = {
    token,
    profile,
    login,
    logout,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}
