import * as PropTypes from 'prop-types'
import React from 'react'
import ChargerName from 'components/ChargerName'
import { Link } from 'react-router-dom'

export default function ChargerSelect({ site }) {
  const chargers = site.circuits?.flatMap(circuit => circuit.chargers)

  return <>
    <h2>Välj laddare</h2>
    <ul>
      {chargers.map(charger => {
        return <li key={charger.id}><Link to={`/site/${site.id}/charger/${charger.id}`}><ChargerName charger={charger} /></Link></li>
      })}
    </ul>
  </>
}

ChargerSelect.propTypes = { products: PropTypes.any }

