import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'auth'
import EnergyPrice from 'components/EnergyPrice'

export default function LoginPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (auth.token) {
      return navigate('/')
    }
  }, [auth.token, navigate])

  const from = location.state?.from?.pathname || "/"

  const login = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget);
    const username = formData.get("username")
    const password = formData.get("password")

    setLoading(true)
    try {
      await auth.login(username, password)
      navigate(from)
    } catch (err) {
      setError(err)
    }
    setLoading(false)
  }

  if (loading) {
    return <p>Loggar in…</p>
  }

  return <>
    <h1>BRF Grafikern – Laddkostnader</h1>
    <p>På den här sidan kan du se kostnaderna för din elbilsladdning.</p>
    <h2>Logga in</h2>
    <p>Använd användarnamnet (epostadress eller mobilnummer inklusive +46) och lösenordet som du har i <a href="https://easee.cloud/">Easee-portalen</a>. Behöver du återställa lösenord eller liknande, gör det där.</p>
    <form onSubmit={login}>
      Användarnamn: <input name="username" /><br />
      Lösenord: <input name="password" type="password" /><br />
      <input type="submit" value="Logga in" />
      <Error err={error} />
    </form>
    <h3>Elpris</h3>
    <p>Här kan du se elpriset idag och upp till 24 timmar frammåt. Priset som visas här är det faktiska, totala priset per kWh som du kommer debiteras för laddning under timmen.</p>
    <EnergyPrice />
  </>
}

function Error({ err }) {
  if (!err) {
    return null
  }
  return <p className="error">{err.title || err.message}</p>
}
