import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom'
import { ChargerPeriodSelect } from 'components/ChargerPeriodSelect'
import React from 'react'

export default function ChargerLayout() {
  const { site } = useOutletContext()
  const { chargerid, period } = useParams()

  const charger = chargerById(site, chargerid)

  return <>
    <p>Visa laddkostnader för <ChargerPeriodSelect siteId={site.id} charger={charger} period={period} /> eller <Link to={`/site/${site.id}/charger/${charger.id}`}>se nuvarande elpris</Link></p>
    <Outlet context={{ charger }} />
  </>
}

function chargerById(site, chargerid) {
  return site.circuits
    ?.flatMap(circuit => circuit.chargers)
    ?.find(charger => charger.id === chargerid)
}
