import React, { useEffect, useRef, useState } from 'react'
import easee from 'lib/easee'
import { useAuth } from 'auth'
import * as PropTypes from 'prop-types'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import XLSX from 'sheetjs-style'
import { addEnergyCosts, getSpotPrices, toBought } from 'lib/chargingCosts'
import download from 'lib/download'
import invoice from 'lib/invoice'
import summerize from 'lib/summarize'
import parsePeriod from 'lib/parsePeriod'

export default function AdminChargingCosts() {
  const { site } = useOutletContext()
  const navigate = useNavigate()
  const { token } = useAuth()
  const { period } = useParams()
  const [loading, setLoading] = useState(true)
  const [chargers, setChargers] = useState(null)
  const shouldFetch = useRef(true)
  const p = parsePeriod(period)

  useEffect(() => {
    // Avoid running the effect twice in strict mode: https://www.youtube.com/watch?v=MXSuOR2yRvQ
    if (!shouldFetch.current) {
      return
    }
    shouldFetch.current = false

    if (!p.isValid) {
      return navigate('/admin')
    }

    async function fetchData() {
      // Get spotPrices and the site's chargers
      const [spotPrices, detailedSite] = await Promise.all([
        getSpotPrices(p.from, p.to),
        easee.site(token, site.id)
      ])

      const allChargers = detailedSite
        .circuits
        ?.flatMap(circuit => circuit.chargers)
        .filter(charger => charger.id)

      let updatedChargers = allChargers.map(charger => {
        return { ...charger, loading: true, lifeTimeEnergyHourly: [] }
      })
      setChargers(updatedChargers)
      setLoading(false)

      async function updateChargerInfo(charger, index) {
        // When fetching the monthly consumption we cannot use UTC as that returns the consumption for the UTC-month
        // while the billing is done for the Europe/Stockholm month.
        let monthlyUsage = await easee.chargerLifeTimeEnergyMonthly(token, charger.id, p.from.year, p.from.month, 'Europe/Stockholm')
        const periodConsumption = monthlyUsage[0].consumption
        updatedChargers[index].periodConsumption = periodConsumption
        if (periodConsumption === 0) {
          updatedChargers[index].loading = false
        }

        setChargers(updatedChargers.concat([]))

        if (updatedChargers[index].periodConsumption === 0) {
          return;
        }

        updatedChargers[index].lifeTimeEnergyHourly = await easee.chargerLifeTimeEnergyHourly(token, charger.id, p.from, p.to)
        updatedChargers[index].loading = false
        setChargers(updatedChargers.concat([]))
      }

      await Promise.all(
        updatedChargers.map((charger, index) => updateChargerInfo(charger, index))
      );

      // Add all the costs
      setChargers(updatedChargers.map(charger => {
        return { ...charger, data:  addEnergyCosts(toBought(charger.lifeTimeEnergyHourly), spotPrices) }
      }))
    }

    fetchData()
  }, [navigate, token, p, site.id])

  return <>
    <h2>Fakturaunderlag</h2>
    <p><Link to={`/site/${site.id}/admin`}>Tillbaka</Link></p>
    {!p.complete && <p>Varning, perioden har inte avslutats ännu så ladda inte ner underlag för fakturering än!</p>}
    {loading && <p>Laddar…</p>}
    {chargers && <Chargers chargers={chargers} period={period} />}
  </>
}

function Chargers({ chargers, period }) {
  const [accountingNumber, setAccountingNumber] = useState(3129)

  const downloadXlsx = (data, filename) =>
  {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = { Sheets: {'data': worksheet }, SheetNames: ['data']}
    const buffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});

    const xlslApplicationType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"

    download(buffer, xlslApplicationType, filename)
  }

  const downloadSummaryXlsx = () => {
    const data = chargers.flatMap(charger => {
      return charger.data
        .filter(row => {
          return row.chargedKwh > 0
        })
        .map(row => {
          return Object.assign({}, row, {
            chargerId: charger.id,
            chargerName: charger.name,
          })
        })
    })
    downloadXlsx(summerize(data, period), `charging-${period}-summary.xlsx`)
  }

  const downloadInvoiceXlsx = () => {
    const data = chargers.flatMap(charger => {
      return charger.data
      .filter(row => {
        return row.chargedKwh > 0
      })
      .map(row => {
        return Object.assign({}, row, {
          chargerId: charger.id,
          chargerName: charger.name,
        })
      })
    })
    downloadXlsx(invoice(data, period, accountingNumber), `charging-${period}-invoice.xlsx`)
  }

  const downloadDetailedXlsx = () => {
    const data = chargers.flatMap(charger => {
      return charger.data
        .filter(row => {
           return row.chargedKwh > 0
        })
        .map(row => {
          return Object.assign({}, row, {
            chargerId: charger.id,
            chargerName: charger.name,
          })
        })
    })
    downloadXlsx(data, `charging-${period}-detailed.xlsx`)
  }

  const handleAccountingNumberChange = (event) => {
    setAccountingNumber(event.target.value)
  }

  const stillLoading = chargers.some(charger => charger.loading)
  return <>
    <ul>
      {chargers.map(charger => {
        return <li key={charger.id}>{charger.name} {charger.periodConsumption && `${charger.periodConsumption.toFixed(2)} kWh`} {charger.loading && 'Laddar…'}</li>
      })}
    </ul>
    <div>
      <label>Bokföringskonto:
        <input value={accountingNumber} onChange={handleAccountingNumberChange}></input>
      </label>
    </div>
    {!stillLoading && <button onClick={downloadInvoiceXlsx}>Ladda ner fakturaunderlag, Xlsx</button>}
    {!stillLoading && <button onClick={downloadSummaryXlsx}>Ladda ner summering, Xlsx</button>}
    {!stillLoading && <button onClick={downloadDetailedXlsx}>Ladda ner detaljerad, Xlsx</button>}
  </>
}

Chargers.propTypes = { chargers: PropTypes.any }

