import { DateTime } from 'luxon'
import React from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default function Totals({ specification, period }) {
  if (!specification) {
    return null
  }

  const totals = calculateTotals(specification)

  return <>
    <h3>Sammanställning</h3>
    <p>Om du vill veta mer om elprisets olika delar, <Link to="/energyprice">läs mer om elpriset här</Link>.</p>
    <div>
      <div id="summary">
        <dl>
          <dt>Period:</dt>
          <dd>{period.humanReadable}{!period.complete && ' (perioden är ej avslutad)'}</dd>
          <dt>Elförbrukning:</dt>
          <dd>{formatDecimal(totals.chargedKwh)} kWh</dd>
          <dt>Ditt genomsnittliga totala elpris (inklusive moms):</dt>
          <dd>{formatDecimal(averagePricePerKwh(totals))} kr/kWh</dd>
          <dt>Total kostnad:</dt>
          <dd>{formatDecimal(totals.grandTotal)} kr</dd>
        </dl>
      </div>
      <div id="invoice">
        <table className="total">
          <tbody>
            <tr>
              <th colSpan="3">Elhandel</th>
            </tr>
            <tr>
              <td>Rörligt elpris</td>
              <td>{formatDecimal(totals.totalSpotPrice)} kr</td>
              <td><span data-text={`${formatDecimal(totals.chargedKwh)} kWh * ${formatDecimal(averageSpotPrice(totals))} kr/kWh = ${formatDecimal(totals.totalSpotPrice)} kr`} className="tooltip"><img alt="" className="info" src="/info.png" /></span></td>
            </tr>
            <tr>
              <td>Påslag elhandlare</td>
              <td>{formatDecimal(totals.totalSurcharge)} kr</td>
              <td><span data-text={`${formatDecimal(totals.chargedKwh)} kWh * ${formatDecimal(averageSurcharge(totals))} kr/kWh = ${formatDecimal(totals.totalSurcharge)} kr`} className="tooltip"><img alt="" className="info" src="/info.png" /></span></td>
            </tr>
            <tr>
              <td>Elcertifikat</td>
              <td>{formatDecimal(totals.totalEnergyCert)} kr</td>
              <td><span data-text={`${formatDecimal(totals.chargedKwh)} kWh * ${formatDecimal(averageEnergyCert(totals))} kr/kWh = ${formatDecimal(totals.totalEnergyCert)} kr`} className="tooltip"><img alt="" className="info" src="/info.png" /></span></td>
            </tr>
            <tr>
              <td colSpan="2" className="total">{formatDecimal(totals.totalTrade)} kr</td>
            </tr>
            <tr>
              <th colSpan="3">Elnät</th>
            </tr>
            <tr>
              <td>Överföringsavgift</td>
              <td>{formatDecimal(totals.totalNetworkFee)} kr</td>
              <td><span data-text={`${formatDecimal(totals.chargedKwh)} kWh * ${formatDecimal(averageNetworkFee(totals))} kr/kWh = ${formatDecimal(totals.totalNetworkFee)} kr`} className="tooltip"><img alt="" className="info" src="/info.png" /></span></td>
            </tr>
            <tr>
              <td>Energiskatt</td>
              <td>{formatDecimal(totals.totalEnergyTax)} kr</td>
              <td><span data-text={`${formatDecimal(totals.chargedKwh)} kWh * ${formatDecimal(averageEnergyTax(totals))} kr/kWh = ${formatDecimal(totals.totalEnergyTax)} kr`} className="tooltip"><img alt="" className="info" src="/info.png" /></span></td>
            </tr>
            <tr>
              <td colSpan="2" className="total">{formatDecimal(totals.totalNetworkFee + totals.totalEnergyTax)} kr</td>
            </tr>
            <tr>
              <th colSpan="3">Totalt</th>
            </tr>
            <tr>
              <td>Elhandel</td>
              <td>{formatDecimal(totals.totalTrade)} kr</td>
            </tr>
            <tr>
              <td>Elnät</td>
              <td>{formatDecimal(totals.totalNetworkFee + totals.totalEnergyTax)} kr</td>
            </tr>
            <tr>
              <td>Moms</td>
              <td>{formatDecimal(totals.totalVat)} kr</td>
            </tr>
            <tr>
              <td colSpan="2" className="total">{formatDecimal(totals.grandTotal)} kr</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </>
}

function calculateTotals(specification) {
  if (!specification) {
    return false
  }
  return specification.reduce((totals, specEntry) => {
    totals.chargedKwh += specEntry.chargedKwh
    totals.totalSpotPrice += specEntry.chargedKwh * specEntry.spotPrice
    totals.totalSurcharge += specEntry.chargedKwh * specEntry.surcharge
    totals.totalEnergyCert += specEntry.chargedKwh * specEntry.energyCert
    totals.totalTrade += specEntry.boughtTrade
    totals.totalNetworkFee += specEntry.boughtNetworkFee
    totals.totalEnergyTax += specEntry.boughtEnergyTax
    totals.totalVat += specEntry.boughtVat
    totals.grandTotal += specEntry.boughtTotal
    return totals
  }, {
    from: DateTime.fromISO(specification.at(0).timestamp).setZone('Europe/Stockholm').toFormat('yyyy-MM-dd'),
    to: DateTime.fromISO(specification.at(-1).timestamp).setZone('Europe/Stockholm').toFormat('yyyy-MM-dd'),
    chargedKwh: 0,
    totalSpotPrice: 0,
    totalSurcharge: 0,
    totalEnergyCert: 0,
    totalTrade: 0,
    totalEnergyTax: 0,
    totalNetworkFee: 0,
    totalVat: 0,
    grandTotal: 0,
  })
}

const formatDecimal = (num, fraction = 2) => new Intl.NumberFormat(['sv-SE'], {
  minimumFractionDigits: fraction,
  maximumFractionDigits: fraction,
}).format(num)

Totals.propTypes = {
  specification: PropTypes.any
}

function averagePricePerKwh(totals) {
  return totals.grandTotal / totals.chargedKwh || 0
}

function averageSpotPrice(totals) {
  return totals.totalSpotPrice / totals.chargedKwh || 0
}

function averageEnergyTax(totals) {
  return totals.totalEnergyTax / totals.chargedKwh || 0
}

function averageSurcharge(totals) {
  return totals.totalSurcharge / totals.chargedKwh || 0
}

function averageEnergyCert(totals) {
  return totals.totalEnergyCert / totals.chargedKwh || 0
}

function averageNetworkFee(totals) {
  return totals.totalNetworkFee / totals.chargedKwh || 0
}
