// NOTE: Don't change, will be extracted to its own package
import config from './config'

const defaultConfig = {
  energyTariff: undefined,
  fixedPrice: NaN,
  energyCert: NaN,
  surcharge: NaN,
}

export default function tradeCosts(ts, configs) {
  const conf = config.select(ts, configs) || defaultConfig
  return {
    energyTariff: conf.energyTariff,
    fixedPrice: conf.fixedPrice,
    energyCert: conf.energyCert,
    surcharge: conf.surcharge,
  }
}
